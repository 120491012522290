import PropTypes from 'prop-types'
import React from 'react'

import './main.scss'

const Layout = ({ children, className }) => (
  <div className='outer-wrapper'>
    <main className={className}>{children}</main>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Layout.defaultProps = {
  className: '',
}

export default Layout
